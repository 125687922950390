import React from 'react'

const RefundPolicy = () => {
  return (
    <div>

<div>
  <section className="page-header padding">
    <div className="container">
      <div className="page-content text-center">
        <h2>Refund Policy</h2>
      </div>
    </div>
  </section>
  <section className="about-section padding">
    <div className="container">
      <div className="row about-wrap">
        <div className="col-lg-12 sm-padding">
          <div className="about-content wow fadeInLeft">
            <h2>Refund Policy:</h2>
            <h4>Our Commitment to Your Peace of Mind</h4>
            <p>At Party Theatre, we believe in providing you with a seamless and worry-free experience when booking with us. Our refund policy is designed to prioritize your convenience while maintaining a fair approach for all our valued customers.</p>
            <p><b>Booking Confirmation with Ease:</b> To secure your reservation, we kindly request an advance payment of 700/-. This small amount goes a long way in confirming your slot, allowing us to prepare for your visit and offer you the best possible service.</p>
            <p><b>Non-Refundable Advance Payment:</b> Please note that the advance payment of 700/- is non-refundable. This policy helps us ensure that we are prepared and ready to provide the best service for your event.</p>
            <p><b>Flexible Cancellation Options:</b> Life can be unpredictable, and we understand that plans might change. If you decide to cancel your booking, please be aware that the advance payment is non-refundable. However, you can cancel your booking up to 72 hours before your scheduled event without any additional charges.</p>
            <p><b>No-Refund Zone:</b> Once we're within the 72-hour window, we gear up to make your experience memorable. Unfortunately, cancellations made after this period will not be eligible for a refund. We're committed to making your time with us special, and this policy ensures we're prepared to serve you better.</p>
            <p><b>Your Punctuality Matters:</b> Your time is valuable, and so is ours. We kindly request you to arrive on time for your booking. Unfortunately, refunds won't be provided for no-shows or late arrivals. It's all part of ensuring a smooth and enjoyable experience for every customer.</p>
            <p><b>Easy Refund Process:</b> Once the refund is approved, the refund amount will be credited with in 7 working days in the original payment method</p>
            <p><b>Always Here to Help:</b> Questions? Concerns? We're here for you. If you have any queries about our refund policy or need assistance with anything else, don't hesitate to reach out. Your satisfaction is our priority, and we're always happy to assist.</p>
            <p>Thank you for choosing Party Theatre. We can't wait to provide you with an amazing experience that you'll cherish.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*<div class="sponsor-section bg-grey">*/}
  {/*    <div class="dots"></div>*/}
  {/*    <div class="container">*/}
  {/*        <div id="sponsor-carousel" class="sponsor-carousel owl-carousel">*/}
  {/*            */}
  {/*        </div>*/}
  {/*    </div>*/}
  {/*</div>*/}
  {/*<div class="icon-bar">*/}
  {/*    */}
  {/*    <a href="https://wa.me/9988-343-343" class="whatsapp"><i class="fab fa-whatsapp"></i></a>*/}
  {/*    */}
  {/*    */}
  {/*    <a href="tel:9988-343-343" class="twitter"><i class="fa fa-phone"></i></a>*/}
  {/*    */}
  {/*    */}
  {/*    <a href="https://instagram.com/_tapesntales__?igshid=MzRlODBiNWFlZA==" target="_blank" class="google"><i class="fab fa-instagram"></i></a>*/}
  {/*    */}
  {/*    */}
  {/*</div>*/}
{/* index.php  29 Nov 2019 03:30:38 GMT */}
</div>



    </div>
  )
}

export default RefundPolicy
