import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addItem, resetState, updateDate } from '../Redux/reducer';
import { useNavigate, useParams } from 'react-router-dom';
import FetchData from '../components/FetchDataComponent';
import DatePicker from 'react-datepicker';
import Loader from '../components/Loader';
import GoldenTheater from '../assets/storage/theaters/dd0acd5a-a326-4989-a0ea-d6f7cc3fb5fc1703344234.jpg';
import SilverTheater from '../assets/img/theatres/nagole/silver.png';
import platinum from "../assets/img/theatres/yusufguda/platinum (1) (1).jpg";
import diamond from "../assets/img/theatres/yusufguda/IMG_9491 (1).jpg"

const theaterImages = {
  gold: GoldenTheater,
  silver: SilverTheater,
  Platinum : platinum,
  Diamond : diamond
};
 
const BookNow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [theatersData, setTheatersData] = useState([]);
  const { location } = useParams();

  const { loading, data, error } = FetchData({
    link: `${process.env.REACT_APP_BASE_URL}api/v1/theater/searchTheatersByLocation/${location}/${selectedDate}`,
  });

  useEffect(() => {
    if (data) {
      // Filter out AUDI 1 and AUDI 2 if location is Yusufguda
      const filteredData = data.data.filter(
        (theater) =>
          !(theater.theater.location === "Yusufguda" && 
            (theater.theater.name === "AUDI 1" || theater.theater.name === "AUDI 2"))
      );
      setTheatersData(filteredData);
    }
  }, [data]);

  useEffect(() => {
    const isSetItemTrue = localStorage.getItem('location');
    if (!isSetItemTrue) {
      navigate('/locations');
    }
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleNavigation = (theater) => {
    if (selectedDate) {
      dispatch(addItem({ name: theater.theater.name, price: theater.theater.price }));
      dispatch(updateDate(selectedDate.toISOString()));
      localStorage.setItem('slotselected', 'true');
      navigate(`/theatres/${location}/${theater.theater.name}`);
    }
  };

  if (error) {
    return <div className=''>Error: {error.message}</div>;
  }

  if (loading) {
    return <Loader />;
  }



  return (
    <div>
      <section className="padding-20" style={{ backgroundImage: 'url(img/bg-dots.html)' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div className="wrapper box-shadow">
                <form action="#">
                  <div className="datepickerdiv">
                    <label htmlFor="true" className="label-text">
                      Check Slot Availability
                    </label>
                    <div className="input-group date">
                      <DatePicker
                        className="form-control datepicker"
                        selected={selectedDate}
                        onChange={handleDateChange}
                        minDate={new Date()}
                        dateFormat="MMMM d, yyyy"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      </section>
      <section className="blog-section padding-20">
        <div className="container">
          <div className="row">
            {theatersData &&
              theatersData.map((theater, index) => (
                <div className="col-sm-6 padding-15" key={index} style={{ display: 'flex' }}>
                  <div className="d-flex h-100 flex-column box-shadow" style={{ flex: 1 }}>
                    <div className="blog-item">
                      <div className="blog-thumb">
                        <img
                          src={theaterImages[theater.theater.name] || GoldenTheater} // Fallback to a default image
                          title={theater.theater.name}
                          alt={theater.theater.name}
                          height={400}
                        />
                        <span className="category">
                          <p>{theater.theater.name}</p>
                        </span>
                      </div>
                      <div className="blog-content">
                        <p className="text-dark">{theater.Description}</p>
                        <div>
                          <h3>
                            {theater.availableSlots} Slots Available on {selectedDate.toLocaleDateString()}
                          </h3>
                        </div>
                        <div className="input-group m-2">
                          <button className="default-btn" onClick={() => handleNavigation(theater)}>
                            Check Slot
                          </button>
                        </div>
                        <p className="text-dark">
                          1-{theater.theater.capacity} people allowed
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default BookNow;
