import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import cakeHD from '../../assets/img/cake-hd.jpg'

import img1 from '../../assets/storage/ckeditor/9_1695201927.png';
import img2 from '../../assets/storage/ckeditor/10_1695201946.png';
import img3 from '../../assets/storage/ckeditor/11_1695201967.png';
import img4 from '../../assets/img/middle-image.jpg';
import food from '../../assets/img/food.jpg';
import ott from '../../assets/img/ott.jpg';
import cake from '../../assets/img/cake.jpg';
import bouq from '../../assets/img/bouq.png';
import banner1 from "../../assets/img/banners/banner1.jpg";
import banner2 from "../../assets/img/location/nagole.png";
import banner3 from "../../assets/img/location/IMG_9455.JPG";
import Loader from '../../components/Loader';
import logo from '../../assets/img/logo.jpg'
import axios from "axios";

const HomeScreen = () => {
  const [isModalOpen, setIsModalOpen] = useState(true); // Modal visibility state

  useEffect(() => {
    console.log('Modal Open State:', isModalOpen);
  }, [isModalOpen]);



  useEffect(() => {
    async function fetchWelcomeMessage() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}`);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching welcome message:', error);
      } 
    }
    fetchWelcomeMessage();
  }, []);

  useEffect(() => {
    Aos.init({ duration: 300 });
  }, []);


  

  // Modal close handler
  const closeModal = () => {
    setIsModalOpen(false);
  };

 

  return (
<div>
{isModalOpen && (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.7)', // Semi-transparent overlay
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
      animation: 'fadeInModal 0.5s ease-in-out', // Fade-in animation for the modal
    }}
  >
    <div
      style={{
        background: '#fff', // White background
        borderRadius: '16px',
        width: '90%',
        maxWidth: '600px',
        padding: '20px',
        textAlign: 'center',
        color: '#333', // Dark text for contrast
        position: 'relative',
        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)', // Soft shadow for a floating effect
        fontFamily: "'Poppins', sans-serif", // Trendy font for modern look
        animation: 'zoomIn 0.5s ease-in-out', // Zoom-in effect for smooth appearance
      }}
    >
      {/* Logo at the top */}
      <div
        style={{
          marginBottom: '20px', 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          animation: 'bounce 1s ease-in-out', // Bounce animation for logo
        }}
      >
        <img
          src={logo} // Add the path to your logo image here
          alt="Party Theatres Logo"
          style={{
            width: '100px', // Adjust size of the logo
            height: 'auto',
            marginBottom: '10px',
          }}
        />
      </div>

      <h2
        style={{
          fontSize: '28px',
          fontWeight: 'bold',
          marginBottom: '12px',
          letterSpacing: '1px',
          textTransform: 'uppercase',
          color: '#6a1b9a', // Purple for the header
          fontFamily: "'Roboto', sans-serif", // Bold and sleek font
          textShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)', // Subtle shadow for depth
          animation: 'fadeIn 1s ease-in-out', // Fade-in effect for title
        }}
      >
        🎉 Celebrate With Us!
      </h2>
      <p
        style={{
          fontSize: '18px',
          fontWeight: '500',
          marginBottom: '16px',
          lineHeight: '1.5',
          color: '#6a1b9a', // Purple for contrast with white
          textTransform: 'uppercase',
          animation: 'fadeIn 1s ease-in-out', // Fade-in effect for text
        }}
      >
        Our 1st Year Anniversary Special Offer!
      </p>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
          animation: 'fadeIn 1s ease-in-out', // Fade-in effect for cake image
        }}
      >
        <img
          src={cakeHD} // Placeholder for cake image
          alt="Free Cake"
          style={{
            width: '180px',
            height: '140px',
            borderRadius: '8px',
            marginRight: '15px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          }}
        />
        <p
          style={{
            fontSize: '16px',
            marginBottom: '20px',
            lineHeight: '1.6',
            color: '#555', // Slightly darker for readability
            flex: '1',
            textAlign: 'left', // Align text to the left
            marginTop: '0', // Ensure no extra space at the top
            animation: 'fadeIn 1s ease-in-out', // Fade-in effect for text
          }}
        >
          Book your slot now and get a <strong style={{ fontWeight: 'bold', color: '#6a1b9a' }}>FREE Cake 🎂</strong> to make your celebration even sweeter!<br />
          <span style={{ fontWeight: 'bold', color: '#e91e63' }}>Hurry! Limited time offer – grab your FREE Cake today! 🎉</span>
        </p>
      </div>

      <button
        style={{
          background: '#6a1b9a', // Purple button color
          color: '#fff',
          fontWeight: 'bold',
          border: 'none',
          borderRadius: '50px',
          padding: '15px 30px',
          fontSize: '16px',
          cursor: 'pointer',
          transition: 'transform 0.3s ease, background 0.3s ease',
          boxShadow: '0 8px 15px rgba(0, 0, 0, 0.1)', // Soft shadow for button depth
          textTransform: 'uppercase',
          animation: 'fadeIn 1s ease-in-out', // Fade-in effect for button
        }}
        onClick={closeModal}
        onMouseOver={(e) => {
          e.target.style.transform = 'scale(1.1)';
          e.target.style.background = '#9c4dcc'; // Slightly lighter purple on hover
        }}
        onMouseOut={(e) => {
          e.target.style.transform = 'scale(1)';
          e.target.style.background = '#6a1b9a'; // Original purple background
        }}
      >
        Book Now 🚀
      </button>

      <div
        style={{
          position: 'absolute',
          top: '-20px',
          left: '-20px',
          width: '60px',
          height: '60px',
          background: '#6a1b9a', // Purple badge
          borderRadius: '50%',
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#fff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          animation: 'pulse 2s infinite', // Pulse effect for urgency
        }}
      >
        Hurry!
      </div>
    </div>
  </div>
)}






  
  <div id="main-slider" className="dl-slider">
 
    <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
      >
        <SwiperSlide>
        <div className="single-slide">
      <div className="bg-img kenburns-top-right" style={{backgroundImage:`url(${banner1})`,backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',}} />
      <div className="overlay" />
      <div className="slider-content-wrap d-flex align-items-center text-left">
        <div className="container">
          <div className="slider-content">
            <div className="inner-layer">
              <div data-animation="fade-in-top" data-delay="1s">Lets Celebrate your Birthday</div>
            </div>
            <div className="dl-caption big">
              <div className="inner-layer">
                <div data-animation="fade-in-bottom" data-delay="2.5s">Party with Us</div>
              </div>
            </div>
            <div className="dl-caption small">
              <div className="inner-layer">
                <div data-animation="fade-in-bottom" data-delay="3s">Welcome to A Party Theatre, where extraordinary experiences await you.<br />
                  Private theatre and event celebration experience.</div>
              </div>
            </div>
            <div className="dl-btn-group">
              <div className="inner-layer">
                <Link to="/locations" className="dl-btn" data-animation="fade-in-left" data-delay="3.5s" >Book Now<i className="arrow_right">&nbsp;</i></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="single-slide">
        <div className="bg-img kenburns-top-right" style={{backgroundImage:`url(${banner2})`,backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',}} />
      <div className="overlay" />
      <div className="slider-content-wrap d-flex align-items-center text-left">
        <div className="container">
        </div>
      </div>
    </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="single-slide">
        <div className="bg-img kenburns-top-right" style={{backgroundImage:`url(${banner3})`,backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',}} />
      <div className="overlay" />
      <div className="slider-content-wrap d-flex align-items-center text-left">
        <div className="container">
          <div className="slider-content">
            <div className="dl-caption medium">
              <div className="inner-layer">
                <div data-animation="fade-in-left" data-delay="1s">Lets Celebrate your</div>
              </div>
            </div>
            <div className="dl-caption big">
              <div className="inner-layer">
                <div data-animation="fade-in-right" data-delay="2s">Bride to be&nbsp;</div>
              </div>
            </div>
            <div className="dl-caption big">
              <div className="inner-layer">
                <div data-animation="fade-in-right" data-delay="2.5s">Party with Us</div>
              </div>
            </div>
            <div className="dl-caption small">
              <div className="inner-layer">
                <div data-animation="fade-in-right" data-delay="3s">Welcome to Party Theatre, your premier destination for an extraordinary<br />
                  private theatre and event celebration experience.</div>
              </div>
            </div>
            <div className="dl-btn-group">
              <div className="inner-layer">
              <Link to="/locations" className="dl-btn" data-animation="fade-in-left" data-delay="3.5s" >Book Now<i className="arrow_right">&nbsp;</i></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        </SwiperSlide>
      </Swiper>
  </div>
  <section className="service-section padding">
  <div className="container">
  <div className="row services-list">
  <div className="col-lg-4 col-sm-6 sm-padding">
  <div data-aos="fade-right">
    <div className="service-item box-shadow text-center">
      <img alt="ABOUT US" src={img1} />
      <h3>Birthday Party</h3>
      <p style={{ color: '#000' }}>Illuminate your birthday with glamour and grandeur! Experience the wonders of our private theatre splendor, where your favorite movies and shows take center stage for a celebration like no other.</p>
    </div>
  </div>
</div>
    <div className="col-lg-4 col-sm-6 sm-padding" data-aos="fade-up" data-aos-delay="300">
      <div className="service-item box-shadow text-center">
        <img alt="Anniversary" src={img2} />
        <h3>Anniversary</h3>
        <p style={{color: '#000'}}>Celebrate your milestone anniversary with a touch of romance! Discover the beauty of love and intimacy in our private theatre affair, where every moment resonates with splendor.</p>
      </div>
    </div>
    <div className="col-lg-4 col-sm-6 sm-padding" data-aos="fade-up" data-aos-delay="400">
      <div className="service-item box-shadow text-center">
        <img alt="Party & Events" src={img3} />
        <h3>Party &amp; Events</h3>
        <p style={{color: '#000'}}>Whether it's a reunion, farewell, or any social soiree in between, our private theatres offer an unmatched and customized setting for your exceptional event.</p>
        <p>&nbsp;</p>
      </div>
    </div>
  </div>
</div>
  </section>
  {/* Section 2 ENDs  */}{/* About Section Starts  */}
  <section className="content-section padding">
    <div className="container">
      <div className="row content-wrap">
        <div className="col-lg-6 sm-padding">
          <div className="content-info "  data-aos="fade-right" data-aos-delay="300"><span>About Us</span>
            <h2>Welcome to Party Theatre </h2>
            <p className="text-white">Where Dreams Unfold on the Big Screen! Your Exclusive Private Theatre Awaits, Offering Not Only Movie Magic but also a Canvas for Celebrations. Elevate Every Occasion with Dolby Atmos Surround Sound and Enchanting 4K Clarity.</p>
            <a className="default-btn" href="about-us.html">Know More</a></div>
        </div>
        <div className="col-lg-6 sm-padding wow fadeInLeft" data-wow-delay="100ms">Explore The Features</div>
      </div>
    </div>
  </section>
  
  <section className="content-section padding">
    <div className="container">
      <div className="row content-wrap">
        <div className="col-lg-6 sm-padding " data-aos="fade-right" ><img alt="content" src={img4} style={{width: 921, height: 518}} /></div>
        <div className="col-lg-6 sm-padding">
          <div className="content-info " data-aos="fade-left" data-aos-delay="300ms"><span>Explore The Features</span>
            <p className="text-white">Party Theatre, we're not just a venue; we're an experience! Prepare to be dazzled in your own private theatre haven. Imagine immersing yourself in the magic of Dolby Atmos sound, where every note and whisper comes alive. The brilliance of enhanced 4K visuals will sweep you into a world of cinematic luxury like never before</p>
            <a className="default-btn" href="contact-us.html">Contact Us</a></div>
        </div>
      </div>
    </div>
  </section>
  {/* Our Clients  Ends*/}
  <p>&nbsp;</p>
  {/* TESTIMONIAL SECTION STARTS */}

  
  <section className="testimonial-section bg-grey padding">
    <div className="dots" />
    <div className="container">
      <div className="section-heading text-center mb-40 " data-aos="fade-left" data-aos-delay="100ms">
        <span>Testimonial</span>
        <h2>What people says</h2>
      </div>
      <div id="testimonial-carousel" className="testimonial-carousel box-shadow owl-carousel">
      </div>
    </div>
  </section> 
  {/* TESTIMONIAL SECTION ENDs */}
  <section className="service-section padding" style={{background: '#fff'}}>
    <div className="container">
      <div className="row services-list  padding-15">
        <div className="col-lg-3 col-sm-6 col-6 sm-padding">
          <div className="service-item box-shadow text-center" data-aos="fade-up" data-aos-delay="100">
            <img src={food} className="img-service" alt="food" />
            <h3>Food </h3>
            <p>Enjoy delicious in-theatre dining options at affordable prices!</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-6 sm-padding">
          <div className="service-item box-shadow text-center" data-aos="fade-up" data-aos-delay="300">
            <img src={ott} className="img-service" alt="ott" />
            <h3>Screening</h3>
            <p>Bring your own OTT accounts and relive the theatre magic!</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-6 sm-padding">
          <div className="service-item box-shadow text-center wow fadeInRight" data-aos="fade-up" data-aos-delay="500">
            <img src={cake} className="img-service" alt="cake" />
            <h3>Cakes</h3>
            <p>Choose the perfect cake for your celebration from our selection.</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6  col-6 sm-padding">
          <div className="service-item box-shadow text-center wow fadeInDown" data-aos="fade-up" data-aos-delay="700">
            <img src={bouq} className="img-service" alt="bouq" />
            <h3>Bouquets</h3>
            <p>Add a beautiful rose bouquet to enhance your celebration.</p>
          </div>
        </div>
      </div>
    </div>
  </section>     



  
  <section className="contact-section bg-grey padding">
    <div className="dots" />
    <div className="container">
      <div className="contact-wrap d-flex align-items-center row">
        <div className="col-md-6 ">
         
          <div style={{width:"100%"}}>
  <iframe
    width="100%"
    height="600"
    frameBorder="0"
    marginHeight="0"
    marginWidth="0"
    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Party%20Theater,%20Surya%20nagar,%20uppal+(A%20Party%20Theatre)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
  >
    <a href="https://www.gps.ie/">gps tracker sport</a>
  </iframe>
</div>
           </div>
        <div className="col-md-6 ">
          <div className="contact-info text-center">
          <h2 style={{ color: '#000', textAlign: 'center' }}>
  Now At Your Convenient Location <br />
  <span style={{
    color: '#9C27B0', /* Purple for Nagole */
    fontWeight: 'bold',
  }}>
    Nagole
  </span>{' '}
  <span style={{ color: '#000', fontWeight: 'normal' }}>and</span>{' '}
  <span style={{
    color: '#673AB7', /* Different shade of purple for Yusufguda */
    fontWeight: 'bold',
  }}>
    Yusufguda Metro Station
  </span>
</h2>
            <a className="default-btn" href="theaters-in-hyderabad.html"> Book Now </a>
            <br />
          </div>
        </div>
      </div>
    </div>
  </section>
 
</div>

  )
}

export default HomeScreen