import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FetchData from '../components/FetchDataComponent';
import { useDispatch } from 'react-redux';
import { resetState } from '../Redux/reducer';
import Loader from '../components/Loader';


// Mapping for location images
const locationImages = {
  "Nagole": require('../assets/img/location/bg.png'),
  "Yusufguda": require('../assets/img/location/IMG_9455.png'),

};

// Function to get the correct image
const getImageForLocation = (locationName) => {
  return locationImages[locationName] ; 
};

const Theatre = () => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [location, setLocation] = useState([]);
  const dispatch = useDispatch();

  const { loading, data, error } = FetchData({ link: `${process.env.REACT_APP_BASE_URL}api/v1/location/allLocations` });

  useEffect(() => {
    if (data) {
      setLocation(data);
    }
    console.log(location)
    dispatch(resetState());
    localStorage.clear();
  }, [dispatch, data]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleNavigation = (location) => {
    localStorage.setItem('location', location);
    navigate(`/theatres/${location}`);
  };

  if (error) {
    return <div className=''>Error: {error.message}</div>;
  }

  if (loading) {
    return <Loader />;
  }
  

  return (
    <div>
      <section className="padding-20" style={{ backgroundImage: 'url(img/bg-dots.html)' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-4"></div>
            {/* <div className="col-md-4">
              <div className="wrapper box-shadow">
                <form>
                  <div className="datepickerdiv">
                    <label htmlFor="true" className="label-text">
                      Check Slot Availability
                    </label>
                    <div className="input-group date">
                      <DatePicker
                        className="form-control datepicker"
                        selected={selectedDate}
                        onChange={handleDateChange}
                        minDate={new Date()}
                        dateFormat="MMMM d, yyyy"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div> */}
            <div className="col-md-4"></div>
          </div>
        </div>
      </section>

      <section className="blog-section padding-20">
        <div className="container">
          <div className="row">
            {location.map((location, index) => (
              <div className="col-sm-6 padding-15" key={index} style={{ display: 'flex' }}>
                <div className="d-flex h-100 flex-column box-shadow" style={{ flex: 1 }}>
                  <div className="blog-item">
                    <div className="blog-thumb">
                      <img
                        src={getImageForLocation(location.locationName)}
                        title={location.locationName}
                        alt={location.locationName}
                        height={400}
                        style={{ width: '100%', objectFit: 'cover' }}
                      />
                      <span className="category">
                        <p>{location.locationName}</p>
                      </span>
                    </div>
                    <div className="blog-content">
                      <div className="input-group m-2">
                        <button
                          className="default-btn"
                          onClick={() => handleNavigation(location.locationName)}
                        >
                          Check Slot
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Theatre;
